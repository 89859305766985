import { retrieveLaunchParams } from "@telegram-apps/sdk";

const devInitData = process.env.REACT_APP_DEV_TELEGRAM_INIT_DATA;

const getInitData = () => {
  const { initDataRaw, initData } = retrieveLaunchParams();

  const result = process.env.REACT_APP_ENV === "DEV" && devInitData
    ? devInitData
    : initDataRaw;

  return {
    initDataRaw: result,
    initData
  };
};

export default getInitData;