import styled from 'styled-components';
import { sizes } from '../../styles/Variables.style';

export const BalanceBarContainer = styled.div`
  position: fixed;
  margin: 0 auto;
  bottom: 0;
  max-width: ${sizes.mainContainer};
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between; 
  background: linear-gradient(99.31deg, rgba(13, 16, 22, 0.8) -9.79%, rgba(17, 30, 54, 0.8) 20.84%, rgba(13, 16, 22, 0.8) 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%);
  // padding: 8px 0;
  padding: 1vh 3vh;
  -webkit-backdrop-filter: blur(2vh); /* Safari и старые версии Chrome */
  backdrop-filter: blur(2vh); /* Современные браузеры */
  z-index: 100;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 40%; 
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Icon = styled.img`
  // width: 20px;
  // height: 20px;
  width: 4vh;
  height: 4vh;
`;

export const NavText = styled.p`
  color: ${({ isActive }) => (isActive ? 'rgba(59, 100, 248, 1)' : 'white')}; 
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
`;



export const WalletButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 100%;
  left: 50%;
  // gap: 4px;
  gap: 8px;
  transform: translate(-50%, -100%);
  flex-direction: column;
  align-items: center;
  // padding-bottom: 8px;
  padding-bottom: 2vh;
`;

export const BalanceAmount = styled.p`
  color: white;
  font-weight: 500;
  line-height: 14px;
  // font-size: 10px;
  font-size: 12px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const WalletButton = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  // width: 40px; 
  // height: 40px;
  width: 8vh;
  height: 8vh;
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(99.31deg, rgba(13, 16, 22, 0.8) -9.79%, rgba(17, 30, 54, 0.8) 20.84%, rgba(13, 16, 22, 0.8) 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.6) 5.44%, rgba(13, 16, 22, 0) 93.15%);
  position: relative;
  box-shadow: 0 2px 0 0 rgba(59, 248, 112, 1);
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;
