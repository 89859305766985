import React from 'react'
import { CloseIcon, DrawerContent, DrawerEl, DrawerTitle, DrawerTitleWrap } from './Drawer.style'

const Drawer = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <DrawerEl isOpen={isOpen}>
      <DrawerContent>
        <DrawerTitleWrap>
          <DrawerTitle>{title}</DrawerTitle>
          <CloseIcon isTitle={title} onClick={() => setIsOpen(false)} src="/Shared/close-white.svg" alt="clear" />
        </DrawerTitleWrap>
        {children}
      </DrawerContent>
    </DrawerEl>
  )
}

export default Drawer