import getInitData from "../utils/getInitData";

const useTelegram = () => {
  const { initData } = getInitData();

  return {
    language: initData.user?.languageCode || "en",
    user: initData.user
  }
}

export default useTelegram;