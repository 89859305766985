import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white; 
  
`;

export const Title = styled.h1` 
  font-size: 2.5rem;
  font-weight: 500;
  
  margin-bottom: 10vh;
`