import styled from 'styled-components';
import { colors, sizes } from '../../styles/Variables.style';

export const WalletContainer = styled.div`
  height: 100%;
  padding: 16px 16px 38px;
  display: flex;
  flex-direction: column;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const WalletConnectWrap = styled.div`

`;

export const WalletHeader = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  position: relative; 
  background-color: ${colors.darkGray}; 
  border-radius: 10px; 
`;

export const TabButton = styled.button`
  background-color: ${({ active }) => (active ? colors.white : 'transparent')}; 
  color: ${({ active }) => (active ? '#000000' : colors.white)};
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 11px 16px;
  border: none;
  cursor: pointer;
  flex: 1;
  position: relative;
  z-index: ${({ active }) => (active ? 2 : 1)}; 
  border-radius: 10px; 

  ${({ active }) => active && `
    box-shadow: 0 2px 0 0 ${colors.blue};
  `}

  transition: background-color 0.1s ease, color 0.3s ease, box-shadow 0.3s ease;
`;



export const WalletConnectRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    border-color: rgba(255, 255, 255, 0.3); 
  }

  &:focus-within {
    border-color: #0088cc; 
  }
`;



export const WalletConnectText = styled.div`
  width: 100%;
  margin-left: 8px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: white;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TopLabel = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  
  color: ${colors.white};
  margin-bottom: 8px;
`;

export const InputContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 400;
  
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;

export const AmountOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  font-weight: 500;
  
  button {
    background-color: ${colors.darkGray};
    color: white;
    padding: 4px 8px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 18%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    box-shadow: 0px 2px 0px 0px rgba(59, 100, 248, 1); 
    
    transition: scale 0.2s ease;
    &:hover {
      scale: 1.1; 
    }
  }
`;

export const MinAmountLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  
  color: rgba(255, 255, 255, 0.6); 
  margin-top: 8px;
  width: 100%;
`;



export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const ButtonTonText = styled.button`
  padding: 14px 16px;  
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  
  background-color: ${colors.white};  
  color: #000000; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;
  width: 100%; 
  box-shadow: 0 3px 0 0 rgba(59, 248, 112, 1), 0vh 0.5vh 1vh 0.5vh rgba(59, 248, 112, 0.5); 

  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
`;

export const TransactionHistoryButton = styled.button`
  padding: 11px 16px;  
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background-color: ${colors.darkGray};  

  color: white; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;
  width: 100%; 

  &:hover {
    background-color: ${colors.darkGrayActive};  
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
`;
