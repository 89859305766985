import React from "react";
import "./Loader.scss";
import { CenteredLoader } from "./Loader.style";
const Loader = ({ overlayBg, isFullScreen = true }) => {
	return (
		<CenteredLoader
			overlayBg={overlayBg}
			isFullScreen={isFullScreen}
		>
			<div className="Loader">
				{[...Array(5)].map((_, i) => (
					<div
						key={i}
						className="cube"
					></div>
				))}
			</div>
		</CenteredLoader>
	);
};

export default Loader;
