import styled from "styled-components";
import { colors } from "../../../styles/Variables.style";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 16, 22, 0.8);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 100; 

  ${({ isOpen }) =>
    isOpen
      ? `
        opacity: 1;
        visibility: visible;
      `
      : `
        opacity: 0;
        visibility: hidden;
      `}
`;

export const DrawerMiniEl = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #10192c;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);


  ${({ isOpen }) => isOpen ? `transform: translateY(0);` : `transform: translateY(100%);`};
`;

export const DrawerMiniContent = styled.div`
  width: 100vw;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 38px;
`;

export const DrawerMiniTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const DrawerMiniTitle = styled.div`
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
`;
