import React, { useState, useEffect } from 'react';
import { ProvidersList, ProviderItem, CheckboxWrapper, CheckboxInput, CheckboxIcon, ProviderLabel, ActionButtons, Button } from './Provider.styles';
import { useProviders } from '../../contexts/ProvidersContext';
import Providers from '../../constants/Providers';
import useTelegram from '../../hooks/useTelegram';

const ProviderSelector = () => {
    const { language } = useTelegram();
    const allName = language === 'ru' ? 'Все провайдеры' : 'All Providers';
    const allProviders = [allName, ...Providers.ALL_PROVIDERS];
    const {selectedProviders, updateSelectedProviders, resetProviders} = useProviders();
    const [curSelectedProviders, setCurSelectedProviders] = useState(selectedProviders);

     // Функция для проверки, выбраны ли все провайдеры
     const areAllProvidersSelected = () => 
      Providers.ALL_PROVIDERS.every(provider => curSelectedProviders.includes(provider));

    const toggleProvider = (providerName) => {
      let updatedProviders;

      if(providerName === allName) {
        if(curSelectedProviders.includes(providerName)) {
          updatedProviders = [];
        } else {
          updatedProviders = allProviders;
        }
      } else if(curSelectedProviders.includes(providerName)) {
        updatedProviders = curSelectedProviders.filter((p) => p !== providerName);
      } else {
        updatedProviders = [...curSelectedProviders, providerName];

        // Если все провайдеры выбраны, добавляем "Все провайдеры"
        if (areAllProvidersSelected()) {
          updatedProviders.push(allName);
        }
      }

      setCurSelectedProviders(updatedProviders);
    };

    const isChecked = (providerName) => curSelectedProviders.includes(providerName);

    const handleSave = () => { 
      let finalSelectedProviders = curSelectedProviders.filter(p => p !== allName);
      updateSelectedProviders(finalSelectedProviders);
    };

    useEffect(() => {
      // Если выбраны все провайдеры, добавляем "Все провайдеры"
      if (areAllProvidersSelected() && !curSelectedProviders.includes(allName)) {
          setCurSelectedProviders((prev) => [...prev, allName]);
      }
      // Если не выбраны все, удаляем "Все провайдеры"
      else if (!areAllProvidersSelected() && curSelectedProviders.includes(allName)) {
          setCurSelectedProviders((prev) => prev.filter((p) => p !== allName));
      }
    }, [curSelectedProviders]);

    return (
      <>
        <ProvidersList>
          {allProviders.map((provider) => (
            <ProviderItem key={provider} onClick={() => toggleProvider(provider)}>
              <CheckboxWrapper>
                <CheckboxInput
                  type="checkbox"
                  checked={isChecked(provider)}
                  onChange={() => toggleProvider(provider)}
                />
                <CheckboxIcon checked={isChecked(provider)} />
              </CheckboxWrapper>
              <ProviderLabel>
                {provider}
              </ProviderLabel>
            </ProviderItem>
          ))}
        </ProvidersList>
        <ActionButtons>
          <Button onClick={() => resetProviders()}>{language === 'ru' ? 'Сбросить' : 'Reset'}</Button>
          <Button primary onClick={handleSave}>{language === 'ru' ? 'Показать' : 'Show'}</Button>
        </ActionButtons>
      </>
    );
};

export default ProviderSelector;
