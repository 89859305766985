import React, { useEffect, useState } from 'react'
import { ClearIcon, InputEl, InputWrap } from './Input.style'

const Input = ({type, value, onChange, placeholder, onFocus, onBlur}) => {
  const handleClear = () => {
    onChange({ target: { value: '' } });
  };

  return (
    <InputWrap>
      <InputEl
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {value && <ClearIcon onClick={handleClear} src="/Shared/close-white.svg" alt="clear" />}
    </InputWrap>
  )
}

export default Input;