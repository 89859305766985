import styled from "styled-components";

export const CenteredLoader = styled.div`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${({ overlayBg }) => overlayBg || "#0d1016"};
	${({ isFullScreen }) =>
		isFullScreen
			? `
    position: fixed;
    width: 100vw;
    height: 101vh;
  `
			: `
    position: absolute;
    width: 100%;
    height: 100%;
  `};
`;
