
import React, { useEffect } from 'react';
import { Container, Title } from './Tournaments.style'; 
import useBackButton from '../../hooks/useBackButton';
import { useNavigate } from 'react-router-dom';

const Tournaments = () => {
  const BackButton = useBackButton();
  const navigate = useNavigate();


  useEffect(() => {
    const onBack = () => navigate(-1);
    BackButton.onClickCstm(onBack);
    return () => {
      BackButton.offClickCstm(onBack);
    };
  }, [navigate]);

  return (
    <Container>
      <Title>Coming soon</Title>
    </Container>
  );
};

export default Tournaments;
