
import styled from 'styled-components';
import { colors } from '../../../styles/Variables.style';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9; 
  display: flex;
  align-items: center;
  padding: 0 16px;

  background-color: rgba(13, 16, 22, 0.8);
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${({ isShow }) =>
    isShow
      ? `
        opacity: 1;
        visibility: visible;
      `
      : `
        opacity: 0;
        visibility: hidden;
      `}
`;


export const ModalContainer = styled.div`
  background: ${colors.bg};
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  width: 100%;
  text-align: center;
  z-index: 10; 

  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  transform: ${({ isShow }) => isShow ? "scale(1)" : "scale(0.8)"};
  transform-origin: ${({ isShow }) => isShow ? "bottom" : "bottom"};
  visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
  pointer-events: ${({ isShow }) => isShow ? "auto" : "none"};
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 10px;
`;


export const ModalMessage = styled.div`

  p {
    
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #b0b3c0;
    margin-top: 8px;
  }
`;

export const ModalMessageTitle = styled.div`
  font-size: ${({ fontSize }) => fontSize || '28px'};
  font-weight: 800;
  line-height: 36px;
`;

export const ModalButton = styled.button`
  padding: 14px 16px;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background-color: ${colors.white};
  color: #000000;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 2px 0px 0px rgba(59, 100, 248, 1), 0px 3px 6px 3px rgba(59, 100, 248, 0.5);

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease;
`;

export const RemainingWagerContainer = styled.div`
  background: rgba(255, 255, 255, 0.06);
  border-radius: 24px;
  padding: 16px;
  margin-top: 16px;
  width: 100%;
  text-align: center;
`;

export const RemainingWagerAmount = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-align: center;
`;

export const RemainingWagerText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.6);
`
