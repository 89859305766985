import React, { useState, useEffect } from 'react';
import {
  BalanceBarContainer,
  IconContainer,
  WalletButtonContainer,
  NavItem,
  NavText,
  BalanceAmount,
  Icon,
  WalletButton,
} from './BalanceBar.styles';
import { getUserBalance } from '../../services/userService';
import { useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import GameTypes from '../../constants/GameTypes';
import useTelegram from '../../hooks/useTelegram';
import Drawer from '../Drawer/Drawer';
import SearchGames from '../Search/Search';

const BalanceBar = () => {
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 
  const [activeTab, setActiveTab] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { language } = useTelegram();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      setIsLoading(true);

      try {
        const response = await getUserBalance();
        const fetchedBalance = parseFloat(response.balance);
        if (isNaN(fetchedBalance)) {
          setBalance(0); 
        } else {
          setBalance(fetchedBalance); 
        }
      } catch (error) {
        console.error("Ошибка при загрузке баланса:", error);
        setBalance(0); 
      } finally {
        setIsLoading(false); 
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
  
    // Определяем активную вкладку
    if (pathname === `/games/${GameTypes.HISTORY}`) {
      setActiveTab('history');
    } else if (pathname === `/games/${GameTypes.FAVOURITE}`) {
      setActiveTab('favorites');
    } else if (pathname === '/tournaments') {
      setActiveTab('tournaments');
    } else {
      setActiveTab('');
    }
  }, [location, searchParams]);

  const handleWalletNavigation = () => {
    navigate('/wallet', { state: { balance, from: location.pathname } });
  };

  const handleHistoryNavigation = () => {
    navigate(`/games/${GameTypes.HISTORY}`);
  };

  const handleFavoritesNavigation = () => {
    navigate(`/games/${GameTypes.FAVOURITE}`);
  };

  const handleTournamentsNavigation = () => {
    navigate('/tournaments');
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); 
  };

  return (
    <>
      <BalanceBarContainer>
        <IconContainer>
          <NavItem onClick={() => setIsSearchOpen(true)}>
            <Icon src={activeTab === 'search' ? "/Main-Page/BalanceBar/searchActive.svg" : "/Main-Page/BalanceBar/SearchIcon.svg"} alt="Поиск" />
            <NavText isActive={activeTab === 'search'}>{language === 'ru' ? 'Поиск' : 'Search'}</NavText>
          </NavItem>
          <NavItem onClick={() => { handleTournamentsNavigation(); handleTabClick('tournaments'); }}>
            <Icon src={activeTab === 'tournaments' ? "/Main-Page/BalanceBar/turActive.svg" : "/Main-Page/BalanceBar/tur.svg"} alt="Турниры" />
            <NavText isActive={activeTab === 'tournaments'}>{language === 'ru' ? 'Турниры' : 'Tournaments'}</NavText>
          </NavItem>
          
        </IconContainer>

        <WalletButtonContainer>
          <WalletButton onClick={handleWalletNavigation}>
            <Icon src="/Main-Page/BalanceBar/walletIcon.png" alt="Wallet" />
          </WalletButton>
          {isLoading ? ( 
            <BalanceAmount>{language === 'ru' ? 'Загрузка...' : 'Loading...'}</BalanceAmount>
          ) : (
            <BalanceAmount>{balance}$</BalanceAmount>
          )}
        </WalletButtonContainer>

        <IconContainer>
          <NavItem onClick={() => { handleFavoritesNavigation(); handleTabClick('favorites'); }}>
            <Icon src={activeTab === 'favorites' ? "/Main-Page/BalanceBar/likeActive.svg" : "/Main-Page/BalanceBar/like.svg"} alt="Избранное" />
            <NavText isActive={activeTab === 'favorites'}>{language === 'ru' ? 'Избранное' : 'Favorites'}</NavText>
          </NavItem>
          <NavItem onClick={() => { handleHistoryNavigation(); handleTabClick('history'); }}>
            <Icon src={activeTab === 'history' ? "/Main-Page/BalanceBar/userActive.svg" : "/Main-Page/BalanceBar/user.svg"} alt="Профиль" />
            <NavText isActive={activeTab === 'history'}>{language === 'ru' ? 'Профиль' : 'Profile'}</NavText>
          </NavItem>
        </IconContainer>
      </BalanceBarContainer>
      <Drawer 
        isOpen={isSearchOpen} 
        setIsOpen={setIsSearchOpen}
        title={language === 'ru' ? 'Поиск игр' : 'Search games'}
      >
        <SearchGames />
      </Drawer>
    </>
  );
};

export default BalanceBar;
