


export const sizes = {
  padding: "16px",
  mainContainer: "750px",
}

export const colors = {
  blue: "#3B64F8",
  white: "#ffffff",
  darkGray: "rgb(29,38,54)",
  darkGrayActive: "rgb(46,46,66)",
  bg: 'linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%), linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%)'
}