import React from 'react';
import {
  FooterContainer,
  FooterLogo,
  SupportButtons,
  SupportButton,
  SupportIcon,
  FooterText,
  ArrowIcon,
  DocumentLinks,
  DocumentLink,
  ButtonTitle,
} from './Footer.styles';
import useTelegram from '../../hooks/useTelegram';

const Footer = () => {
  const { language } = useTelegram();

  const currentYear = new Date().getFullYear();

  const copyright = language === 'ru' ?
  `© ${currentYear} Все права защищены. BETON принадлежит и управляется компанией SHARTEY INTERNATIONAL LIMITED, юридический адрес: 3076 Sri Francis Drake's Highway, Road Town, Tortola, British Virgin Islands.` :
  `© ${currentYear} All rights reserved. BETON is owned and operated by SHARTEY INTERNATIONAL LIMITED, registered office: 3076 Sri Francisco Drake's Highway, Road Town, Tortola, British Virgin Islands.`;
  
  const links = [
    {
      name: language === 'ru' ? 'Политика конфиденциальности' : 'Privacy Policy',
      link: "#"
    },
    {
      name: language === 'ru' ? 'Условия использования' : 'Terms of Use',
      link: "#"
    },
    {
      name: language === 'ru' ? 'Политика безопасности игроков' : 'Player Safety Policy',
      link: "#"
    }
  ];
  
  return (
    <FooterContainer>
      <FooterLogo src="/Main-Page/Footer/logo.png" alt="Beton Logo" />
      <SupportButtons>
        <SupportButton>
          <SupportIcon src="/Main-Page/Footer/chat.svg" alt="Online Chat" />
          <div>
            <ButtonTitle>{language === 'ru' ? 'Online чат' : 'Online Chat'}</ButtonTitle>
            <span>{language === 'ru' ? 'На связи 24/7' : 'In touch 24/7'}</span>
          </div>
        </SupportButton>
        <SupportButton>
          <SupportIcon src="/Main-Page/Footer/mail.svg" alt="Support" />
          <div>
            <ButtonTitle>{language === 'ru' ? 'Поддержка' : 'Support'}</ButtonTitle>
            <span>support@betton</span>
          </div>
        </SupportButton>
      </SupportButtons>

      <DocumentLinks>
        {links.map((item, index) => {
          return (
            <DocumentLink href={item.href} key={index}>
              {item.name}
              <ArrowIcon src="/Main-Page/Footer/urlImg.svg" alt="Arrow" />
            </DocumentLink>
          )
        })}
      </DocumentLinks>

      <FooterText>
        {copyright}
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
