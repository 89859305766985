import React, { useState, useEffect, useReducer } from 'react';
import { useTonConnectUI, useTonAddress, useTonConnectModal } from '@tonconnect/ui-react';
import { 
  WalletContainer, 
  InputContainer, 
  InputLabel, 
  ButtonTonText, 
  TabsContainer, 
  TabButton, 
  AmountOptions, 
  WalletConnectRow, 
  WalletConnectText, 
  MinAmountLabel,
  TopLabel,
  ButtonContainer, 
  TransactionHistoryButton, 
  ContentWrap,
  WalletConnectWrap,
  Icon,
  WalletHeader
} from './Wallet.styles';
import { verifyTransaction, getMinDeposit, getRemainingWagering } from '../../services/userService'; 
import { withdrawFunds, sendTonTransaction } from '../../services/tonServices'; 
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import useBackButton from '../../hooks/useBackButton';
import trackMixpanel from '../../utils/trackEvent';
import useTelegram from '../../hooks/useTelegram';
import Input from '../../components/Input/Input';
import DrawerMini from '../../components/Drawer/DrawerMini/DrawerMini';
import WalletManage from '../../components/WalletPopups/WalletManage';
import StatusModal from './StatusModal/StatusModal';

const handleAmountInput = (value, setValue) => {
  const regex = /^\d*\.?\d{0,2}$/; 
  if (regex.test(value)) {
    setValue(value);
  }
};

const ActiveTab = {
  deposit: 'deposit',
  withdraw: 'withdraw'
}

const ModalType = {
  success: 'success',
  error: 'error',
  wagering: 'wagering',
  loading: 'loading'
}

const Wallet = () => {
  const [amount, setAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [minDeposit, setMinDeposit] = useState(1.62); 
  const [minWithdraw, setMinWithdraw] = useState(1.62);
  const [remainingWagering, setRemainingWagering] = useState(0);
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonAddress();
  const { open, state: connectModalState } = useTonConnectModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(ActiveTab.deposit);
  const [modalType, setModalType] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const BackButton = useBackButton();
  const { language } = useTelegram();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [walletManageTitle, setWalletManageTitle] = useState('');

  useEffect(() => {
    if(localStorage.getItem("hasWallet")) return;
    if(connectModalState.status == "closed" && connectModalState.closeReason == "wallet-selected") {
      trackMixpanel("Wallet connected", { balance: location.state?.balance });
      localStorage.setItem("hasWallet", true);
    }
  }, [connectModalState])

  useEffect(() => {
    trackMixpanel("Cashbox", { referrer: location.state?.from || location.pathname });
  }, []);

  useEffect(() => {
    const fetchMinDeposit = async () => {
      try {
        const result = await getMinDeposit();
        setMinDeposit(result.minDeposit);
        setMinWithdraw(result.minWithdraw);
      } catch (error) {
        console.error('Ошибка при получении минимального депозита:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    const fetchRemainingWagering = async () => {
      try {
        const remaining = await getRemainingWagering(); 
        setRemainingWagering(remaining);
      } catch (error) {
        console.error('Error fetching remaining wagering:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchRemainingWagering();
    fetchMinDeposit();
  }, []);

  useEffect(() => {
    const onBack = () => navigate(-1);
    BackButton.onClickCstm(onBack);
    return () => {
      BackButton.offClickCstm(onBack);
    };
  }, [navigate]);

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const handleConnectWallet = async () => {
    trackMixpanel("Wallet connect", { balance: location.state?.balance });
    tonConnectUI.setConnectRequestParameters({
      state: 'ready',
      items: [{ name: 'ton_addr' }]
    });
    open();
  };
  
  const handleSendTransaction = async () => {
    if (!wallet) {
      await handleConnectWallet();
      return;
    }

    if(amount < minDeposit) return;

    try {
      const transaction = await sendTonTransaction(wallet, amount, 'Пополнение баланса');

      if (!transaction?.messages) {
        throw new Error('Некорректная структура транзакции');
      }

      const result = await tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: transaction.messages.map((msg) => ({
          address: msg.to,
          amount: msg.value,
          payload: msg.payload,
          bounce: true,
        })),
        keepOpened: true,
      });

      setModalType(ModalType.loading);
      setIsShowModal(true);
      const verifyResponse = await verifyTransaction(
        result.boc, 
        wallet,
        amount,
        'Deposit'
      );

      if (verifyResponse?.success) {
        setModalType(ModalType.success);
        setIsShowModal(true);
        trackMixpanel("Deposit successful", { amount });
      } else {
        throw new Error(verifyResponse?.message);
      }
    } catch (error) {
      setModalType(ModalType.error);
      setIsShowModal(true);
      trackMixpanel("Deposit error", { error: error.message });
    }
  };

  const handleWithdraw = async () => {
    if (!wallet) {
      handleConnectWallet();
      return;
    }

    if(withdrawAmount < minWithdraw) return;

    if (!withdrawAmount || parseFloat(withdrawAmount) <= 0) {
      return;
    }

    try {
      if(!checkRemainingWagering()) return;

      const amountToSend = parseFloat(withdrawAmount).toString();
      await withdrawFunds(wallet, amountToSend, 'Withdrawal from Casino');

      setModalType(ModalType.success);
      setIsShowModal(true);
    } catch (error) {
      setModalType(ModalType.error);
      setIsShowModal(true);
    }
  };

  const handleTabClick = async (tab) => {
    if (tab === ActiveTab.withdraw) {
      if(!checkRemainingWagering()) return;
    }
    setActiveTab(tab);
  };

  const checkRemainingWagering = () => {
    if(remainingWagering > 0) {
      setModalType(ModalType.wagering);
      setIsShowModal(true);
      return false;
    }
    return true;
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleTransactionHistory = () => {
    navigate('/transaction-history');
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <>
      <WalletContainer>
        {/* <WalletHeader>
          <Icon src="/Shared/close-white.svg" alt="close" />
        </WalletHeader> */}
        <TabsContainer>
          <TabButton active={activeTab === ActiveTab.deposit} onClick={() => handleTabClick(ActiveTab.deposit)}>
            {language === 'ru' ? 'Пополнение' : 'Deposit'}
          </TabButton>
          <TabButton active={activeTab === ActiveTab.withdraw} onClick={() => handleTabClick(ActiveTab.withdraw)}>
            {language === 'ru' ? 'Вывод' : 'Withdraw'}
          </TabButton>
        </TabsContainer>


        <WalletConnectWrap onClick={!wallet ? handleConnectWallet : toggleDrawer}>
          {activeTab === ActiveTab.deposit ? (
            <TopLabel>{language === 'ru' ? 'Способ пополнения' : 'Payment Method'}</TopLabel>
          ) : (
            <TopLabel>{language === 'ru' ? 'Способ вывода' : 'Withdraw Details'}</TopLabel>
          )}

          <WalletConnectRow>
            <Icon src={wallet ? '/Wallet-Page/Deposit/Usdt.svg' : "/Wallet-Page/Deposit/plus-circle.svg"} alt="+" />
            <WalletConnectText>
              {wallet ? shortenAddress(wallet) : language === 'ru' ? 'Привязать кошелёк' : 'Connect Wallet'}
            </WalletConnectText>
            <Icon src="/Wallet-Page/Deposit/arrow-right.svg" alt=">" />
          </WalletConnectRow>
        </WalletConnectWrap>

        {activeTab === ActiveTab.deposit && (
          <>
            <ContentWrap>
              <InputContainer>
                <InputLabel>{language === 'ru' ? 'Сумма пополнения' : 'Deposit Amount'}</InputLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={(e) => handleAmountInput(e.target.value, setAmount)}
                  placeholder={language === 'ru' ? '0.00' : '0.00'}
                />
                <MinAmountLabel>{language === 'ru' ? `Мин. сумма: ${minDeposit}` : `Min. amount: ${minDeposit}`}</MinAmountLabel>
                <AmountOptions>
                  {[50, 100, 150, 200, 1500].map((option) => (
                    <button key={option} onClick={() => setAmount(option)}>
                      {option}
                    </button>
                  ))}
                </AmountOptions>
              </InputContainer>
            </ContentWrap>
          </>
        )}

        {activeTab === ActiveTab.withdraw && (
          <>
            <ContentWrap>
              <InputContainer>
                <InputLabel>{language === 'ru' ? 'Сумма вывода' : 'Withdraw Amount'}</InputLabel>
                <Input
                  type="number"
                  value={withdrawAmount}
                  onChange={(e) => handleAmountInput(e.target.value, setWithdrawAmount)}
                  placeholder={language === 'ru' ? '0.00' : '0.00'} 
                />
                <MinAmountLabel>{language === 'ru' ? `Мин. сумма: ${minWithdraw}` : `Min. amount: ${minWithdraw}`}</MinAmountLabel>
              </InputContainer>
            </ContentWrap>
          </>
        )}

        <ButtonContainer>
          <TransactionHistoryButton onClick={handleTransactionHistory}>
            {language === 'ru' ? 'История операций' : 'Transaction History'}
          </TransactionHistoryButton>
          {activeTab === ActiveTab.deposit ? (
            <ButtonTonText onClick={handleSendTransaction}>
              {language === 'ru' ? 'Пополнить' : 'Deposit'}
            </ButtonTonText>
          ) : (
            <ButtonTonText onClick={handleWithdraw}>
              {language === 'ru' ? 'Вывести' : 'Withdraw'}
            </ButtonTonText>
          )}
        </ButtonContainer>
      </WalletContainer>

      <DrawerMini 
        isOpen={isDrawerOpen && wallet} 
        setIsOpen={setIsDrawerOpen} 
        title={walletManageTitle}
      >
        <WalletManage
          wallet={{
            name: 'Tether (TON)',
            shortenedAddress: shortenAddress(wallet),
            address: wallet,
          }}
          activeTab={activeTab}
          setTitle={setWalletManageTitle}
          isDrawerOpen={isDrawerOpen}
        />
      </DrawerMini>
      
      <StatusModal 
        isShow={isShowModal}
        onClose={() => setIsShowModal(null)}
        modalType={modalType}
        activeTab={activeTab}
        remainingWagering={remainingWagering}
      />
    </>
  );
};

export default Wallet;