import styled from 'styled-components';
import { sizes } from '../../styles/Variables.style';



export const MainContainer = styled.div`

`;

export const FixedBackground = styled.div`
  width: 100%;
  height: 26.3vh;
  background: url('/Main-Page/Main-Images/Banner.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const GradientGrid = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 8px;
`;

export const GamesGridHorizontalScroll = styled.div`
  display: grid;
  padding: ${sizes.padding} ${sizes.padding} 0;
  grid-template-rows: repeat(3, 1fr); 
  grid-auto-flow: column; 
  grid-auto-columns: 31.8%; 
  gap: 8px;
  width: 100%;
  overflow-x: auto; 
  overflow-y: hidden; 
`;

export const GameImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
  object-fit: contain; 
  border-radius: 10px;
`;


export const WhiteBarWithDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 4px;
  margin: 0 auto;
  margin-top: 8px;
`;
