import React from 'react'
import { GameCard, GameGrid, GameImage, NoGamesMessage } from './GamesGrid.style';
import { useNavigate } from 'react-router-dom';
import useTelegram from '../../hooks/useTelegram';

const GamesGrid = ({ games, errorMessage, gridComponent: GridComponent = GameGrid }) => {
  const navigate = useNavigate();
  const { language } = useTelegram();


  const handleStartGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_identifier || game.game_id, 
        gameImage: game.src,
        gameName: game.name,
        providerName: game.provider 
      } 
    });
  };

  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id} onClick={() => handleStartGame(game)}>
        <GameImage
          src={`${process.env.REACT_APP_BACKEND_URL}${game.src}` || '/path/to/default-image.png'}
          alt={game.name || 'Game'} 
        />
      </GameCard>
    ));
  };

  return (
    <GridComponent>
      {games.length > 0 ? renderGames(games) : 
        <NoGamesMessage>
          {language === 'ru' ? 'Пока ничего нет' : 'No new games available'}
          <div>{errorMessage}</div>
        </NoGamesMessage>
      }
    </GridComponent>
  )
}

export default GamesGrid