import styled from 'styled-components';
import { colors } from '../../styles/Variables.style';

export const NoGamesTitle = styled.h2`
  font-size: 1rem;
  font-weight: 800;
  
  color: white;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 2vh;
`;

export const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
`;

export const GameCard = styled.div`
  background-color: #121429;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; 
  &:hover {
    transform: scale(1.05);
  }
`;

export const GameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  aspect-ratio: 3/4;
`;

export const NoGamesMessage = styled.div`
  grid-column: span 3;
  grid-row: span 3;
  background: #FFFFFF0F;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  height: calc(100vw / 3 * 3);
  padding: 2vh;

  div {
    margin-top: 1vh;
    
    opacity: 0.6;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
`;