import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { 
  GamesContainer,
  GamesWrap,
} from './Games.styles';
import { getGames, getNavigationMenuItemById, getNewGames } from '../../services/userService'; 
import TabsNavigation from '../../components/TabsNavigation/TabsNavigation';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import ProvidersSection from '../../components/ProvidersSection/ProvidersSection';
import { useProviders } from '../../contexts/ProvidersContext';
import GamesGrid from '../../components/GamesGrid/GamesGrid';
import GameTypes from '../../constants/GameTypes';
import useTelegram from '../../hooks/useTelegram';
import { SectionTitle } from '../../styles/Shared.style';

const Games = () => {
  const { language } = useTelegram();
  const [games, setGames] = useState([]);
  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState(language === 'ru' ? 'Нет игр' : 'No games available');
  const [isLoading, setIsLoading] = useState(true); 
  const { selectedProviders } = useProviders();
  const [searchParams] = useSearchParams();
  const { type } = useParams();

  const setPageType = () => {
    switch(type) {
      case GameTypes.NEW:
        setTitle(language === 'ru' ? 'Новинки' : 'New Games');
        setErrorMessage(language === 'ru' ? 'Тут будут новые игры' : 'New games will be here');
        break;
      case GameTypes.POPULAR:
        setTitle(language === 'ru' ? 'Популярные' : 'Popular Games');
        setErrorMessage(language === 'ru' ? 'Тут будут популярные игры' : 'Popular games will be here');
        break;
      case GameTypes.FAVOURITE: 
        setTitle(language === 'ru' ? 'Избранное' : 'Favourites');
        setErrorMessage(language === 'ru' ? 'Тут будут ваши любимые игры' : 'Your favorite games will be here');
        break;
      case GameTypes.HISTORY: 
        setTitle(language === 'ru' ? 'История' : 'History');
        setErrorMessage(language === 'ru' ? 'Тут будут игры в которые сыграете' : 'Here will be the games you play');
        break;
      default:
        setTitle("");
        setErrorMessage(language === 'ru' ? 'Нет игр' : 'No games available');
    }
  }

  useEffect(() => {
    const tabId = searchParams.get('tab-id');

    if (type != GameTypes.NAVIGATION) {
      setPageType();
      fetchGames();
    } else if (tabId) {
      setIsLoading(true);
      getNavigationMenuItemById(tabId).then((item) => {
        setTitle(language === "ru" ? item.name_ru : item.name_en);
        fetchGames(item);
      }).catch((error) => {
        console.error("Error fetching navigation menu item:", error);
        setIsLoading(false);
        setTitle("");
        setGames([]); 
      });
    }
  }, [searchParams, selectedProviders, type]);

  const fetchGames = async (tabItem) => {
    const provider = type !== GameTypes.NAVIGATION ? selectedProviders : tabItem?.provider;
    setIsLoading(true);
    try {
      const games = await getGames(type, provider, tabItem?.game_category);
      setGames(games);
    } catch (error) {
      console.error("Error fetching games:", error);
      setGames([]);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <GamesContainer>
        <TabsNavigation />

        {type !== GameTypes.NAVIGATION && <ProvidersSection />}

        <SectionTitle>{title}</SectionTitle>
        
        <GamesWrap>
          <GamesGrid 
            games={games} 
            errorMessage={errorMessage}
          />
        </GamesWrap>

        <Footer />
    </GamesContainer>
  );
};

export default Games;
