import styled from 'styled-components';
import { colors } from '../../styles/Variables.style';
import { ScrollContainer } from '../../styles/Containers.style';

export const HistoryContainer = styled.div`
  background: ${colors.bg};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TabsWrapper = styled.div`

`;

export const TabsContainer = styled.div`
  display: flex;
  overflow: auto;
  padding: 16px;
  width: 100%; 
  gap: 8px;
`;

export const FilterButton = styled.button`
  display: flex; 
  align-items: center;
  padding: 5px 5px 5px 12px;
  padding-right: ${({ active }) => (active ? '30px' : '12px')};
  background-color: ${({ active }) => (active ? '#ffffff' : 'rgba(255, 255, 255, 0.06)')};
  color: ${({ active }) => (active ? '#000000' : 'white')};
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  text-align: center;
  box-shadow: ${({ active }) => (active ? '0 0 10px rgba(59, 100, 248, 0.5)' : 'none')};
  position: relative; 
  white-space: nowrap;
  transition: padding-right 0.3s ease, background-color 0.3s ease, color 0.3s ease; 
`;

export const CloseIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 5px; 
`;

export const TransactionListContainer = styled(ScrollContainer)`
  flex: 1;
  margin: 0px 10px 0 16px;
  padding-right: 6px;
  padding-bottom: 16vh;
`;

export const TransactionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const TransactionIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 16px;
`;

export const TransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const TransactionDate = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  white-space: nowrap;
`;
