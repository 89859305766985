import styled from "styled-components";
import { colors, sizes } from "../../styles/Variables.style";


export const DrawerEl = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.bg};
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  z-index: 101;

  ${({ isOpen }) => isOpen ? `transform: translateY(0);` : `transform: translateY(100%);`};
`;

export const DrawerContent = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 38px;
`;


export const DrawerTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 16px 0;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const DrawerTitle = styled.div`
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
`;