import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Main from './pages/Main/Main';
import Wallet from './pages/Wallet/Wallet';
import BalanceBar from './components/BalanceBar/BalanceBar';
import GamePage from './pages/GamePage/GamePage';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import Tournaments from './pages/Tournaments/Tournaments';
import { defineEventHandlers, on } from '@telegram-apps/sdk';
import trackMixpanel from './utils/trackEvent';
import Games from './pages/Games/Games';
import { ProvidersProvider } from './contexts/ProvidersContext';
import { GlobalStyles } from './styles/GlobalStyles.style';
import { AppContainer } from './styles/Containers.style';
import TransactionHistory from './pages/TransactionHistory/TransactionHistory';

const AppContentWithRouter = () => {
  const location = useLocation();

  useEffect(() => {
    // console.error = () => {};
    // console.warn = () => {};
    // console.log = () => {};

    trackMixpanel("Session Started");

    defineEventHandlers();

    const removeVisibilityListener = on("visibility_changed", e => {
      if(!e.is_visible) {
        trackMixpanel("App Minimized");
      }
    });

    return () => {
      removeVisibilityListener();
    };
  }, []);

  return (
    <AppContainer>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/game" element={<GamePage />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/games/:type" element={<Games />} />
        <Route path="/transaction-history" element={<TransactionHistory />} />
        <Route path="/tournaments" element={<Tournaments />} />
      </Routes>
      {location.pathname !== '/wallet' && location.pathname !== '/game' && (
        <BalanceBar />
      )}
    </AppContainer>
  );
};

const App = () => {

  return (
    <TonConnectUIProvider
      manifestUrl="https://betongame.club/tonconnect-manifest.json"
      enableAndroidBackHandler={false}
      uiPreferences={{
        logLevel: 'silent',
        theme: THEME.DARK
    }}
    >
      <ProvidersProvider>
        <Router>
          <AppContentWithRouter />
        </Router>
      </ProvidersProvider>
      <GlobalStyles />
    </TonConnectUIProvider>
  );
};

export default App;
