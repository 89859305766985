import React, { createContext, useContext, useEffect, useState } from 'react';
import Providers from '../constants/Providers';

const ProvidersContext = createContext();

const FilterValidProviders = (providers) => {
  return providers.filter((provider) => Providers.ALL_PROVIDERS.includes(provider));
};

const checkAndInitializeProviders = () => {
  let validProviders = [];
  try {
    const rawData = localStorage.getItem('selectedProviders');
    const selected = JSON.parse(rawData) || [];

    if (Array.isArray(selected)) {
      // Фильтруем валидные провайдеры
      validProviders = FilterValidProviders(selected);
    }
  } catch (error) {
    console.warn("Error providers init: ", error);
  }

  // Если что-то пошло не так, возвращаем все провайдеры
  validProviders = validProviders.length > 0 ? validProviders : Providers.ALL_PROVIDERS;
  localStorage.setItem('selectedProviders', JSON.stringify(validProviders));
  return validProviders;
};

export const ProvidersProvider = ({ children }) => {
  const [selectedProviders, setSelectedProviders] = useState(checkAndInitializeProviders());

  const updateSelectedProviders = (providers) => {
    let validProviders = FilterValidProviders(providers);
    validProviders = validProviders.length > 0 ? validProviders : Providers.ALL_PROVIDERS;
    setSelectedProviders(validProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(validProviders));
  };

  const resetProviders = () => {
    setSelectedProviders(Providers.ALL_PROVIDERS);
    localStorage.setItem('selectedProviders', JSON.stringify(Providers.ALL_PROVIDERS));
  };

  const removeProvider = (provider) => {
    const updated = selectedProviders.filter((p) => p !== provider);
    updateSelectedProviders(updated);
  };

  return (
    <ProvidersContext.Provider
      value={{ selectedProviders, resetProviders, removeProvider, updateSelectedProviders }}
    >
      {children}
    </ProvidersContext.Provider>
  );
};

export const useProviders = () => useContext(ProvidersContext);
