import GameTypes from "../constants/GameTypes";
import getInitData from "../utils/getInitData";
const serverUrl = process.env.REACT_APP_BACKEND_URL;

const { initDataRaw } = getInitData();

const fetchWithAuth = async (url, options = {}) => {
  const headers = {
    'Accept': 'application/json',
    'Authorization': `Bearer ${initDataRaw}`,
    ...options.headers,
  };

  return fetch(url, { ...options, headers });
};

const getUserLanguage = async () => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/language`, {});
  const data = await response.json();
  return data.languageCode;
};
const getUserBalance = async () => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/balance`, {});
  return await response.json();
};

const verifyTransaction = async (boc, senderAddress, amount, transactionType) => {
  try {
    const response = await fetchWithAuth(`${serverUrl}/api/transactions/verify-transaction`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ boc, senderAddress, amount, transactionType }),
    });

    if (!response.ok) {
      throw new Error('Ошибка верификации транзакции');
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при верификации транзакции:', error);
    return { success: false, message: error.message };
  }
};



const getMinDeposit = async () => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/min-deposit`, {});
  return await response.json();
};

const startBgamingGame = async (gameIdentifier, language, isDemo) => {
  const url = isDemo ? "/demo" : "/sessions";
  const response = await fetchWithAuth(`${serverUrl}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ gameIdentifier, language }),
  });

  const data = await response.json();
  return data.gameUrl;
};


const startZephyrGame = async (gameIdentifier, isDemo) => {
  const response = await fetchWithAuth(`${serverUrl}/game/${gameIdentifier}?demo=${isDemo}`, {});
  const data = await response.json();
  return data.url;
}

const getRemainingWagering = async () => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/get-wagering`, {});
  const data = await response.json();
  return data.remainingWagering;
};


const addToFavorites = async (gameId, provider, name, src) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/favorites`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ gameId, provider, name, src }),
  });
  return await response.json();
};



const removeFromFavorites = async (gameId) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/favorites/${gameId}`, {
    method: 'DELETE',
  });
  return await response.json();
};

const getTransactions = async (filter) => {
  const queryParam = filter !== 'all' ? `?type=${filter}` : '';
  const response = await fetchWithAuth(`${serverUrl}/api/transactions/history${queryParam}`, {});
  return await response.json();
};

const getNavigationMenuItems = async () => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/navigation-menu`, {});
  return await response.json();
};

const getNavigationMenuItemById = async (id) => {
  const response = await fetchWithAuth(`${serverUrl}/api/users/navigation-menu/${id}`, {});
  return await response.json();
};

const getGames = async (type, providers, category) => {
  const params = new URLSearchParams();

  if (providers) {
    if (Array.isArray(providers)) {
      const providersString = providers.join(',');
      params.append('provider', providersString);
    } else {
      params.append('provider', providers);
    }
  }

  if (category && type === GameTypes.NAVIGATION) {
    params.append('category', category);
  }

  const queryString = params.toString();
  const url = `/api/users/games/${type}${queryString ? `?${queryString}` : ''}`;

  const response = await fetchWithAuth(`${serverUrl}${url}`, {});
  
  if (!response.ok) {
    throw new Error("Error " + response.statusText);
  }

  return await response.json();
};


export { 
  getUserLanguage, 
  getUserBalance, 
  startBgamingGame,
  startZephyrGame,
  getMinDeposit,
  getRemainingWagering,
  removeFromFavorites,
  addToFavorites,
  getTransactions,
  verifyTransaction,
  getNavigationMenuItems,
  getNavigationMenuItemById,
  getGames,
};
