import styled from "styled-components";
import { colors } from "../../styles/Variables.style";



export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const InputEl = styled.input`
  padding: 11px 16px;
  font-size: 14px; 
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  border: 1px solid #414854; 
  border-radius: 14px;
  background-color: ${colors.darkGray}; 
  color: white;

  &::placeholder {
    color: #777c85; 
    font-size: 14px;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #0088cc; 
  }

  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ClearIcon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
`;