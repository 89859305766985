import mixpanel from 'mixpanel-browser';
import getInitData from './getInitData';

const { initData } = getInitData();

const trackMixpanel = (eventName, properties) => {
  if(initData.user && process.env.REACT_APP_ENV !== "DEV") {
    mixpanel.track(eventName, {
      "user_id": initData.user.id,
      "timestamp": new Date(Date.now()).toISOString(),
      ...properties
    });
    console.log(eventName);
  }
};

export default trackMixpanel;
