import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HistoryContainer,
  TransactionListContainer,
  TransactionItem,
  TransactionDetails,
  FilterButton,
  TabsContainer,
  TransactionIcon,
  TransactionDate,
  CloseIcon,
  TabsWrapper,
} from './TransactionHistory.styles';
import { getTransactions } from '../../services/userService';
import useBackButton from '../../hooks/useBackButton';
import useTelegram from '../../hooks/useTelegram';
import { SectionTitle } from '../../styles/Shared.style';

const tabs = [
  {
    value: "Deposit",
    text: {
      ru: "Только депозиты",
      en: "Only deposits"
    }
  },
  {
    value: "withdrawal",
    text: {
      ru: "Только выводы",
      en: "Only withdrawals"
    }
  }
]

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const { language } = useTelegram();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const userTransactions = await getTransactions(filter);
        const sortedTransactions = userTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTransactions(sortedTransactions);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTransactions();
  }, [filter]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  useEffect(() => {
    const onBack = () => navigate(-1);
    BackButton.onClickCstm(onBack);
    return () => {
      BackButton.offClickCstm(onBack);
    };
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} • ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  return (
    <HistoryContainer>
      <SectionTitle>{language === 'ru' ? 'История операций' : 'Transaction History'}</SectionTitle>
      <TabsWrapper>
        <TabsContainer>
          {tabs.map((item) => (
            <FilterButton active={filter === item.value} onClick={() => handleFilterChange(item.value)}>
              {item.text[language]}
              {filter === item.value && (
                <CloseIcon
                  src="/Shared/close-dark.svg"
                  alt="Close"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleFilterChange('all');
                  }}
                />
              )}
            </FilterButton>
          ))}
        </TabsContainer>
      </TabsWrapper>

      <TransactionListContainer>
        {transactions.map((transaction) => (
          <TransactionItem key={transaction.id}>
            <TransactionIcon
              src={transaction.transaction_type === 'Deposit' ? '/Wallet-Page/receive.svg' : '/Wallet-Page/send.svg'}
              alt={transaction.transaction_type === 'Deposit' ? 'Deposit' : 'Withdrawal'}
            />
            <TransactionDetails>
              <div>
                {transaction.transaction_type === 'Deposit' ? '+' : '-'} {transaction.amount} USDT
              </div>
              <TransactionDate>{formatDate(transaction.created_at)}</TransactionDate>
            </TransactionDetails>
          </TransactionItem>
        ))}
      </TransactionListContainer>
    </HistoryContainer>
  );
};

export default TransactionHistory;
