import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { TabsContainer, TabsWrapper, Tab, Icon, Logo, LogoWrap } from './TabsNavigation.styles';
import { getNavigationMenuItems } from '../../services/userService';
import GameTypes from '../../constants/GameTypes';
import useTelegram from '../../hooks/useTelegram';

const TabsNavigation = () => {
  const location = useLocation();
  const activeTabRef = useRef(null);
  const [navItems, setNavItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { language } = useTelegram();

  // Данные для табов
  const staticTabs = [
    { id: GameTypes.POPULAR, to: `/games/${GameTypes.POPULAR}`, label: { ru: 'Популярные', en: 'Popular' } },
    { id: GameTypes.NEW, to: `/games/${GameTypes.NEW}`, label: { ru: 'Новинки', en: 'New' } },
    { id: 'dynamic', dynamic: true }, // Заполнитель для динамических табов
    { id: GameTypes.HISTORY, to: `/games/${GameTypes.HISTORY}`, label: { ru: 'История', en: 'History' }, icon: '/Tabs/history.svg', activeIcon: '/Tabs/historyActive.svg' },
    { id: GameTypes.FAVOURITE, to: `/games/${GameTypes.FAVOURITE}`, label: { ru: 'Избранное', en: 'Favorites' }, icon: '/Tabs/like.svg', activeIcon: '/Tabs/likeActive.svg' },
  ];

  // Загружаем динамические элементы меню
  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const response = await getNavigationMenuItems();
        setNavItems(
          response.map((item) => ({
            id: item.id,
            to: `/games/${GameTypes.NAVIGATION}?tab-id=${item.id}`,
            label: { ru: item.name_ru, en: item.name_en },
            icon: item.icon_inactive && process.env.REACT_APP_BACKEND_URL + item.icon_inactive,
            activeIcon: item.icon_active && process.env.REACT_APP_BACKEND_URL + item.icon_active
          }))
        );
      } catch (error) {
        console.error('Error fetching nav items:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNavItems();
  }, []);

  // Скроллинг активного таба
  const scrollToActiveTab = () => {
    if (activeTabRef.current) {
      activeTabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      scrollToActiveTab();
    }
  }, [navItems, isLoading]);

  // Объединяем статические и динамические элементы
  const combinedTabs = staticTabs.flatMap((tab) =>
    tab.dynamic ? navItems : tab
  );

  const isTabActive = (tab) => {
    return searchParams.get('tab-id') == tab.id || searchParams.get('type') == tab.id || location.pathname == tab.to;
  }

  // Рендеринг табов
  const renderTabs = (tabs) =>
    tabs.map((tab) => {
      return (
        <NavLink
          key={tab.id}
          to={tab.to}
          style={{ textDecoration: 'none' }}
        >
          {({ isActive }) => {
            const active = isActive && isTabActive(tab);
            return (
              <Tab ref={active ? activeTabRef : null} isActive={active}>
                {tab.icon && (
                  <Icon
                    src={active && tab.activeIcon ? tab.activeIcon : tab.icon}
                    alt={tab.label[language]}
                  />
                )}
                {tab.label[language]}
              </Tab>
            )
          }}
        </NavLink>
      );
    });

  return (
    <TabsWrapper>
      <TabsContainer>
        <NavLink to="/">
          <LogoWrap>
            <Logo src="/Main-Page/Footer/logo.png" alt="Beton Logo" />
          </LogoWrap>
        </NavLink>
        {renderTabs(combinedTabs)}
      </TabsContainer>
    </TabsWrapper>
  );
};

export default TabsNavigation;
