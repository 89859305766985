import styled from 'styled-components';
import { colors, sizes } from '../../styles/Variables.style';

export const ProviderContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: ${sizes.padding};
  overflow-x: auto; 
  white-space: nowrap; 
`;

export const ProviderButton = styled.div`
  display: flex; 
  align-items: center;
  padding: 5px 5px 5px 12px;
  background-color: ${colors.white};
  color: rgba(13, 16, 22, 1);
  
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  border: none;
  border-radius: 8px; 
  cursor: pointer;
  box-shadow: 0 0 5px rgba(59, 100, 248, 0.3);
  white-space: nowrap;
  box-shadow: 0 2px 0 0 ${colors.blue};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const RemoveIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  cursor: pointer;
`;

export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background-color: ${colors.darkGray};
  
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: padding-right 0.3s ease, background-color 0.3s ease, color 0.3s ease;
`;
