import React, { useState, useEffect } from 'react';
import {
  FixedBackground,
  WhiteBarWithDots,
  GradientGrid,
  GamesGridHorizontalScroll,
  TabsNavigationWrap,
  ContentWrap,
  MainContainer,
} from './Main.styles';
import Footer from '../../components/Footer/Footer';
import { getPopularGames, getNewGames, getGames } from '../../services/userService';
import Loader from '../../components/Loader/Loader';
import TabsNavigation from '../../components/TabsNavigation/TabsNavigation';
import GameTypes from '../../constants/GameTypes';
import { useProviders } from '../../contexts/ProvidersContext';
import GamesGrid from '../../components/GamesGrid/GamesGrid';
import useTelegram from '../../hooks/useTelegram';
import { SectionTitle } from '../../styles/Shared.style';

const Main = () => {
  const [popularGames, setPopularGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const {selectedProviders} = useProviders();
  const { language } = useTelegram();

  useEffect(() => {
    const fetchGames = async () => {
      setIsLoading(true); 

      try {
        const popularGamesData = await getGames(GameTypes.POPULAR, selectedProviders);
        setPopularGames(popularGamesData);

        const newGamesData = await getGames(GameTypes.NEW, selectedProviders);
        setNewGames(newGamesData);
      } catch (error) {
        console.error("Ошибка при загрузке игр:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, [selectedProviders]);

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <MainContainer>
      <FixedBackground />
      <WhiteBarWithDots />
      <TabsNavigation />
      <ContentWrap>
        <GradientGrid>
          <SectionTitle>{language === 'ru' ? 'Популярное' : 'Popular'}</SectionTitle>
          <GamesGrid 
            games={popularGames} 
            errorMessage={language === 'ru' ? 'Тут будут популярные игры' : 'Popular games will be here'} 
            gridComponent={GamesGridHorizontalScroll}
          />
        </GradientGrid>
        <GradientGrid>
          <SectionTitle>{language === 'ru' ? 'Новинки' : 'New'}</SectionTitle>
          <GamesGrid 
            games={newGames} 
            errorMessage={language === 'ru' ? 'Нет новых игр' : 'No new games available'} 
            gridComponent={GamesGridHorizontalScroll}
          />
        </GradientGrid>
      </ContentWrap>

      <Footer />
    </MainContainer>
  );
};

export default Main;
