import React from 'react'
import { Dropdown, DropdownItem } from './SearchDropdown.style'

const SearchDropdown = ({ isDropdownOpen, filteredGames, handleSelectGame }) => {
  return (
    <>
      <Dropdown isDropdownOpen={isDropdownOpen}>
        {filteredGames.length > 0 && filteredGames.map((game) => (
          <DropdownItem key={game.id} onMouseDown={() => handleSelectGame(game)}>
            {game.name}
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  )
}

export default SearchDropdown