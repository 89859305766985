import { createGlobalStyle } from "styled-components";
import { colors } from "./Variables.style";


export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rounded Mplus 1c', sans-serif;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }


  body, html {
    background: ${colors.bg};
    color: ${colors.white};
    overflow: hidden;
    touch-action: none;

    -ms-user-select: none; 
		-moz-user-select: none; 
		-webkit-user-select: none; 
		user-select: none; 
  }
`;
