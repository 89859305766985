import React, { useState } from 'react';
import { ProvidersButton, ProvidersButtonWrap, ProvidersIcon, SelectedProvidersCount } from './ProvidersSection.style';
import SelectedProviders from '../SelectedProviders/SelectedProviders';
import { useNavigate } from 'react-router-dom';
import { useProviders } from '../../contexts/ProvidersContext';
import useTelegram from '../../hooks/useTelegram';
import ProviderSelector from '../Providers/Provider';
import Drawer from '../Drawer/Drawer';

const ProvidersSection = () => {
  const { selectedProviders, resetProviders, removeProvider } = useProviders();
  const navigate = useNavigate();
  const { language } = useTelegram();
  const [isProvidersOpen, setIsProvidersOpen] = useState(false);

  const handleProvidersClick = () => {
    navigate('/providers');
  };

  return (
    <>
      <ProvidersButtonWrap>
        <ProvidersButton onClick={() => setIsProvidersOpen(true)}>
          {language === 'ru' ? 'Все провайдеры' : 'All Providers'}
          {selectedProviders.length > 0 && (
            <SelectedProvidersCount>{selectedProviders.length}</SelectedProvidersCount>
          )}
          <ProvidersIcon src='/History-Page/arrow-down.svg' alt="Arrow" />
        </ProvidersButton>
      </ProvidersButtonWrap>

      {selectedProviders.length > 0 && (
        <SelectedProviders
          selectedProviders={selectedProviders}
          onRemoveProvider={removeProvider}
          onResetProviders={resetProviders}
        />
      )}

      <Drawer
        isOpen={isProvidersOpen}
        setIsOpen={setIsProvidersOpen} 
        title={language === 'ru' ? 'Провайдеры' : 'Providers'}
      >
        <ProviderSelector />
      </Drawer>
    </>
  );
};

export default ProvidersSection;