import React, { useState, useEffect, useRef } from 'react';
import { 
  WalletOption, 
  WalletText, 
  WalletRadioButton, 
  WalletIcon,
  WalletDeleteIcon,
  WalletTextWrap,
  ChangeButton,
  WalletOptionsContainer,
  WalletManageContainer
} from './WalletManage.style'; 
import useTelegram from '../../hooks/useTelegram';
import { useTonConnectUI } from '@tonconnect/ui-react';

const WalletManage = ({ wallet, activeTab, setTitle, isDrawerOpen }) => {
  const [isChecked, setIsChecked] = useState(true);
  const [isDeletion, setIsDeletion] = useState(false); 
  const { language } = useTelegram();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if(isDeletion) {
      setTitle(language === 'ru' ? 'Удаление кошельков' : 'Removing wallets');
    } else {
      setTitle(activeTab === 'deposit' ? (
        language === 'ru' ? 'Способы пополнения' : 'Payment Method'
        ) : (
        language === 'ru' ? 'Способы вывода' : 'Withdraw Method'
        )
      );
    }
  }, [isDeletion, activeTab])

  useEffect(() => {
    if(!isDrawerOpen) {
      setIsDeletion(false);
    }
  }, [isDrawerOpen])


  const toggleChecked = () => {
    if(isDeletion) return;
    setIsChecked(!isChecked);
  };

  const handleDeleteWallet = async () => {
    try {
      await tonConnectUI.disconnect();
      localStorage.removeItem("hasWallet");
    } catch (error) {
      console.error('Error while deleting wallet:', error);
    }
  };

  return (
    <>
      <WalletOptionsContainer>
        <WalletOption onClick={toggleChecked}>
          <WalletTextWrap>
            <WalletIcon src="/Wallet-Page/Deposit/Usdt.svg" alt="USDT Icon" /> 
            <WalletText>{wallet.name} {wallet.shortenedAddress}</WalletText>
          </WalletTextWrap>
          {isDeletion ? (
            <WalletIcon 
              src="/Wallet-Page/Deposit/trash.png" 
              alt="Delete Wallet" 
              onClick={handleDeleteWallet} 
            />
          ) : (
            <WalletRadioButton checked={isChecked} />
          )}
        </WalletOption>
      </WalletOptionsContainer>
      <ChangeButton onClick={() => setIsDeletion(!isDeletion)}>
        {isDeletion ? (language === 'ru' ? 'Назад' : 'Back') : (language === 'ru' ? 'Изменить' : 'Change')}
      </ChangeButton>
    </>
  );
};

export default WalletManage;
