import styled from "styled-components";
import { sizes } from "./Variables.style";


export const SectionTitle = styled.h2`
  padding: ${sizes.padding} ${sizes.padding} 0;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
`;