import styled from 'styled-components';
import { colors } from '../../styles/Variables.style';

export const WalletOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  overflow-y: auto;
  height: 50vh;
`;


export const WalletText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const WalletOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.darkGrayActive};
  cursor: pointer;
  width: 100%;
`;

export const WalletTextWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WalletRadioButton = styled.div`
  width: 20px;
  height: 20px;
  
  background-image: ${({ checked }) =>
    checked
      ? "url('/Wallet-Page/Deposit/radio-checked.png')"
      : "url('/Wallet-Page/Deposit/radio-unchecked.png')"};
  background-size: cover;
  cursor: pointer;
`;

export const WalletIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const ChangeButton = styled.button`
  padding: 11px 16px;  
  margin: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background-color: ${colors.darkGray}; 

  color: white; 
  border: none;
  border-radius: 10px; 
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkGrayActive};  
  }

  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
`;

