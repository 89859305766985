import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  SearchContainer,
  RandomButton,
  RandomIcon ,
  NoGamesFoundContainer,
  NoGamesText,
  CheckText,
  SearchTitle,
  SearchGamesWrap,
  SearchInputWrap,
} from './Search.styles';
import { getGames } from '../../services/userService';
import Loader from '../../components/Loader/Loader';
import GameTypes from '../../constants/GameTypes';
import GamesGrid from '../../components/GamesGrid/GamesGrid';
import useTelegram from '../../hooks/useTelegram';
import Input from '../Input/Input';
import SearchDropdown from './SearchDropdown/SearchDropdown';

const SearchGames = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [popularGames, setPopularGames] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();
  const { language } = useTelegram();
  
  useEffect(() => {
    const fetchAllGames = async () => {
      try {
        const allGames = await getGames(GameTypes.ALL); 
        setGames(allGames);
        setFilteredGames(allGames);
      } catch (error) {
        console.error("Error fetching all games:", error);
      }
    };

    fetchAllGames();
  }, []);

  useEffect(() => {
    const fetchPopularGames = async () => {
      try {
        const popular = await getGames(GameTypes.POPULAR);
        setPopularGames(popular);
      } catch (error) {
      } finally {
        setIsLoading(false); 
      }
    };

    fetchPopularGames();
  }, []);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredGames(games);
      setIsDropdownOpen(false);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = games.filter((game) =>
        game.name.toLowerCase().includes(lowerQuery)
      );
      setFilteredGames(filtered);
      setIsDropdownOpen(filtered.length > 0);
    }
  }, [searchQuery, games]);

  const handleSelectGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_identifier || game.game_id,
        gameImage: game.src,
        gameName: game.name,
        providerName: game.provider
      } 
    });
  };

  const handleRandomGameClick = () => {
    if (games && games.length > 0) {
      const randomGame = games[Math.floor(Math.random() * games.length)];
      handleSelectGame(randomGame); 
    }
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <MainContainer noResults={filteredGames.length === 0}>
        <SearchContainer>
          <SearchInputWrap>
            <Input
              type="text"
              value={searchQuery}
              onFocus={() => setIsDropdownOpen(true)}
              onBlur={() => setIsDropdownOpen(false)}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={language === 'ru' ? 'Введите название игры' : 'Enter game name'}
            />
            <SearchDropdown
              isDropdownOpen={isDropdownOpen} 
              filteredGames={filteredGames}
              handleSelectGame={handleSelectGame}
            />
          </SearchInputWrap>
          <RandomButton onClick={handleRandomGameClick}>
            <RandomIcon src="/Search-Page/random.svg" alt="Random Game" />
            Random Game
          </RandomButton>
        </SearchContainer>

        {searchQuery && filteredGames.length > 0 && (
          <>
            <SearchGamesWrap>
              <SearchTitle>{language === 'ru' ? 'Результаты поиска' : 'Search Results'}</SearchTitle>
              <GamesGrid 
                games={filteredGames}
              />
            </SearchGamesWrap>
          </>
        )}

        {searchQuery && filteredGames.length === 0 && (
          <NoGamesFoundContainer>
            <NoGamesText>{language === 'ru' ? 'Ничего не найдено' : 'No games found'}</NoGamesText>
            <CheckText>{language === 'ru' ? 'Проверьте правильность написания' : 'Check your spelling'}</CheckText>
          </NoGamesFoundContainer>
        )}

        {!searchQuery && popularGames.length > 0 && (
          <>
            <SearchGamesWrap>
              <SearchTitle>{language === 'ru' ? 'Популярные игры' : 'Popular Games'}</SearchTitle>
              <GamesGrid 
                games={popularGames}
              />
            </SearchGamesWrap>
          </>
        )}
    </MainContainer>
  );
};

export default SearchGames;
