import { backButton } from "@telegram-apps/sdk";

const useBackButton = () => {
  return {
    ...backButton,
    onClickCstm(callback) {
      backButton.onClick(callback);
      if(!backButton.isVisible()) {
        backButton.show();
      }
    },
    offClickCstm(callback) {
      backButton.offClick(callback);
      if(backButton.isVisible()){
        backButton.hide();
      }
    }
  }
};

export default useBackButton;