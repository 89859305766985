import React from 'react';
import { ProviderContainer, ProviderButton, RemoveIcon, ResetButton } from './SelectedProviders.style';
import useTelegram from '../../hooks/useTelegram';


const SelectedProviders = ({ 
  selectedProviders,
  onRemoveProvider, 
  onResetProviders, 
}) => {
  const { language } = useTelegram();

  return (
    <ProviderContainer>
      {selectedProviders.map((provider) => (
        <ProviderButton key={provider}>
          {provider}
          <RemoveIcon src="/Shared/close-dark.svg" alt="Удалить" onClick={() => onRemoveProvider(provider)} />
        </ProviderButton>
      ))}
      {selectedProviders.length > 0 && (
        <ResetButton onClick={() => onResetProviders()}>
          {language === 'ru' ? 'Сбросить всё' : 'Reset All'}
        </ResetButton>
      )}
    </ProviderContainer>
  );
};

export default SelectedProviders;
