import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GameContainer,
  GameImage,
  GameTitle,
  GameProvider,
  ButtonContainer,
  DemoButton,
  PlayButton,
  ErrorMessage,
  LikeButton,
  LikeIcon,
  GameTitleContainer,
  IframeContainer,
} from './Game.styles';
import { addToFavorites, removeFromFavorites, getFavoriteGames, startBgamingGame, startZephyrGame, getGames } from '../../services/userService';
import Loader from '../../components/Loader/Loader';
import useBackButton from '../../hooks/useBackButton';
import trackMixpanel from '../../utils/trackEvent';
import Providers from '../../constants/Providers';
import GameTypes from '../../constants/GameTypes';
import useTelegram from '../../hooks/useTelegram';

const GamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [gameUrl, setGameUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const { gameIdentifier, gameImage, gameName, providerName } = location.state;

  const [error, setError] = useState('');
  const [isLiked, setIsLiked] = useState(false);
  const BackButton = useBackButton();
  const { language } = useTelegram();

  useEffect(() => {
    const checkIfFavorite = async () => {
      try {
        const favoriteGames = await getGames(GameTypes.FAVOURITE, providerName);
        const isGameFavorite = favoriteGames.some(game => game.game_id === gameIdentifier);
        setIsLiked(isGameFavorite);
      } catch (error) {

      }
    };

    checkIfFavorite();
  }, [gameIdentifier, providerName]);

  const handleStartGame = async (isDemo) => {
    setIsLoading(true); 
    try {
      let gameUrl;

      switch(providerName) {
        case Providers.BGAMING:
          gameUrl = await startBgamingGame(gameIdentifier, language, isDemo);
          break;
        case Providers.ZEPHYR:
          gameUrl = await startZephyrGame(gameIdentifier, isDemo);
          break;
        default:
          return;
      }
  
      setGameUrl(gameUrl);
      trackMixpanel(isDemo ? 'Demo launch' : 'Game launch', { game_name: gameName });
    } catch (error) {
      const errorMessage = language === 'ru' ? 'Ошибка при создании игровой сессии' : 'Error starting game session';
      setError(errorMessage);
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    const onBack = () => navigate(-1);
    BackButton.onClickCstm(onBack);
    return () => {
      BackButton.offClickCstm(onBack);
    };
  }, [navigate]);

  const handleLike = async () => {
    if (isLiked) {
      await removeFromFavorites(gameIdentifier);
      setIsLiked(false);
    } else {
      await addToFavorites(gameIdentifier, providerName, gameName, gameImage);
      setIsLiked(true);
    }
  };

  return (
    <GameContainer>
      {isLoading ? ( 
        <Loader />
      ) : gameUrl ? ( 
        <IframeContainer>
          <iframe src={gameUrl} title="Game" frameBorder="0" allowFullScreen />
        </IframeContainer>
      ) : (
        <>
          <GameTitleContainer>
            <div>
              <GameTitle>{gameName}</GameTitle>
              <GameProvider>{providerName}</GameProvider>
            </div>
            <LikeButton onClick={handleLike}>
              <LikeIcon isLiked={isLiked} />
            </LikeButton>
          </GameTitleContainer>
          
          <GameImage src={`${process.env.REACT_APP_BACKEND_URL}${gameImage}`} alt={gameName} />
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
          
          <ButtonContainer>
            <DemoButton onClick={() => handleStartGame(true)}>
              {language === 'ru' ? 'Демо режим' : 'Demo Mode'}
            </DemoButton>
            <PlayButton onClick={() => handleStartGame(false)}>
              {language === 'ru' ? 'Играть' : 'Play'}
            </PlayButton>
          </ButtonContainer>
        </>
      )}
    </GameContainer>
  );
};

export default GamePage;
