import styled from 'styled-components';
import { colors, sizes } from '../../styles/Variables.style';

export const TabsWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  /* Чтобы горизонтальная прокрутка работала корректно */
  width: 100%;
  display: block;
`;

export const TabsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: ${sizes.padding};
  width: fit-content; /* Настройка для прокрутки, если элементы выходят за границы */
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`

export const Logo = styled.img`
  height: 100%;
`;

export const Tab = styled.div`
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? colors.white : colors.darkGray)};
  color: ${({ isActive }) => (isActive ? '#000000' : 'white')};
  
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  white-space: nowrap; /* Убедимся, что текст не переносится на новую строку */
  box-shadow: ${({ isActive }) => (isActive ? `0 2px 0 0 ${colors.blue}` : 'none')};
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;
