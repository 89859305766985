import React, { useState, useEffect } from "react";
import {
	ModalOverlay,
	ModalContainer,
	ModalContent,
	ModalMessage,
	ModalButton,
	Icon,
	RemainingWagerContainer,
	RemainingWagerAmount,
	RemainingWagerText,
	ModalMessageTitle,
} from "./StatusModal.style";
import useTelegram from "../../../hooks/useTelegram";
import Loader from "../../../components/Loader/Loader";

const getModalContent = (type, activeTab, language) => {
	const isDeposit = activeTab === "deposit";

	const modalContent = {
		success: {
			icon: "/Wallet-Page/SuccessIcon.png",
			title: isDeposit
				? language === "ru"
					? "Успешно!"
					: "Success!"
				: language === "ru"
				? "Вывод успешен!"
				: "Withdrawal successful!",
			message: isDeposit
				? language === "ru"
					? "Оплата прошла успешно, средства уже на вашем балансе."
					: "Payment was successful, funds are already in your balance."
				: language === "ru"
				? "Вывод средств прошел успешно, средства списаны с вашего баланса."
				: "Withdrawal was successful, funds have been deducted from your balance.",
			buttonText: isDeposit
				? language === "ru"
					? "Играть"
					: "Play"
				: language === "ru"
				? "Закрыть"
				: "Close",
		},
		error: {
			icon: "/Wallet-Page/ErrorIcon.png",
			title: isDeposit
				? language === "ru"
					? "Что-то пошло не так"
					: "Something went wrong"
				: language === "ru"
				? "Ошибка при выводе"
				: "Withdrawal error",
			message: isDeposit
				? language === "ru"
					? "При оплате произошла ошибка, можете попробовать ещё раз."
					: "There was an error during payment, you can try again."
				: language === "ru"
				? "При выводе средств произошла ошибка, попробуйте снова."
				: "There was an error during the withdrawal, please try again.",
			buttonText: isDeposit
				? language === "ru"
					? "Попробовать ещё раз"
					: "Try again"
				: language === "ru"
				? "Повторить вывод"
				: "Retry withdrawal",
		},
		wagering: {
			icon: null,
			title: language === "ru" ? "Вывод недоступен" : "Error during withdrawal",
			message:
				language === "ru"
					? "Чтобы совершить вывод, вам необходимо отыграть сумму в 2 раза больше от суммы первого депозита."
					: "You have not met the wagering requirements for withdrawal. Please complete the wagering to proceed.",
			buttonText: language === "ru" ? "Продолжить играть" : "Try again",
		},
	};

	return modalContent[type] || null;
};

const StatusModal = ({ isShow, onClose, modalType, activeTab, remainingWagering }) => {
	const [modalContent, setModalContent] = useState(null);
	const { language } = useTelegram();

	useEffect(() => {
		setModalContent(getModalContent(modalType, activeTab, language));
	}, [modalType, activeTab]);

	return (
		<>
			<ModalOverlay isShow={isShow}>
				{modalType === "loading" ? (
					<Loader
						isFullScreen={false}
						overlayBg={"transparent"}
					/>
				) : (
					<ModalContainer isShow={isShow}>
						<ModalContent>
							{modalContent?.icon && <Icon src={modalContent.icon} />}
							<ModalMessage>
								<ModalMessageTitle fontSize={modalType === "wagering" ? "22px" : "28px"}>
									{modalContent?.title}
								</ModalMessageTitle>
								<p>{modalContent?.message}</p>
							</ModalMessage>

							{modalType === "wagering" && (
								<RemainingWagerContainer>
									<RemainingWagerAmount>{remainingWagering} USDT</RemainingWagerAmount>
									<RemainingWagerText>
										{language === "ru" ? "Осталось получить" : "Remaining to wager"}
									</RemainingWagerText>
								</RemainingWagerContainer>
							)}

							<ModalButton onClick={onClose}>{modalContent?.buttonText}</ModalButton>
						</ModalContent>
					</ModalContainer>
				)}
			</ModalOverlay>
		</>
	);
};

export default StatusModal;
