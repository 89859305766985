import React from 'react';
import { CloseIcon, DrawerMiniContent, DrawerMiniEl, DrawerMiniTitle, DrawerMiniTitleWrap, Overlay } from './DrawerMini.style';

const DrawerMini = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <DrawerMiniEl isOpen={isOpen}>
        <DrawerMiniContent>
          <DrawerMiniTitleWrap>
            <DrawerMiniTitle>{title}</DrawerMiniTitle>
            <CloseIcon onClick={() => setIsOpen(false)} src="/Shared/close-white.svg" alt="clear" />
          </DrawerMiniTitleWrap>
          {children}
        </DrawerMiniContent>
      </DrawerMiniEl>
    </>
  );
}

export default DrawerMini;
